import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Link, LinkMethod } from '@model';
import { AuthMean } from '@model/evd/auth-means';
import { ZodType, z } from 'zod';

import { AbstractEvdApiService } from '../evd-api.service';
import {
  AuthMeanJson,
  AuthMeanJsonSchema,
  deserializeAuthMean,
  serializeAuthMean,
} from './io/auth-mean.io';

@Injectable()
export class AuthMeansApiService extends AbstractEvdApiService<
  AuthMean,
  AuthMeanJson
> {
  public constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  protected serialize(item: AuthMean): AuthMeanJson {
    return serializeAuthMean(item);
  }

  protected deserialize(item: AuthMeanJson): AuthMean {
    return deserializeAuthMean(item);
  }

  protected getLinksLink(): Link {
    return {
      href: '/driver/auth-means/.links',
      method: LinkMethod.GET,
    };
  }

  protected getJsonSchema(): ZodType<AuthMeanJson> {
    return AuthMeanJsonSchema;
  }

  protected getListJsonSchema(): z.Schema<AuthMeanJson> {
    return this.getJsonSchema();
  }

  protected listDeserialize(item: AuthMeanJson): AuthMean {
    return this.deserialize(item);
  }
}
