import { IdJsonSchema, MultiLanguageJsonSchema } from '@api';
import { LinksJson } from '@api/hateos';
import { environment } from '@env/evd/environment';
import { NEW } from '@model';
import { AuthMean } from '@model/evd/auth-means';
import { z } from 'zod';

export const AuthMeanJsonSchema = z.object({
  authMeanId: IdJsonSchema.optional(),
  name: z.string(),
  token: z.string(),
  driverContractId: IdJsonSchema.optional(),
  driverContractName: MultiLanguageJsonSchema.optional(),
  _links: LinksJson(environment.urls.api).optional(),
});

export type AuthMeanJson = z.infer<typeof AuthMeanJsonSchema>;

export function serializeAuthMean(item: AuthMean): AuthMeanJson {
  return {
    name: item.name,
    token: item.token,
    driverContractId: item.driverContractId,
  };
}

export function deserializeAuthMean(item: AuthMeanJson): AuthMean {
  return {
    ...item,
    id: item.authMeanId ?? NEW,
  };
}
